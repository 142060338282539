import styled from "styled-components";
import { Container } from "../../styles/UI";

export const Hero = styled.header`
  /* max-height: 986px; */
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 120px;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;

  ${Container} {
    .cta {
      position: relative;
      z-index: 10;

      h1 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
        line-height: 1.25;
      }

      p {
        font-weight: 350;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }

    .illustration {
      z-index: 1;
      margin-top: 0px;
      position: relative;

      .bg-logo {
        position: absolute;
        z-index: 0;
        height: 800px;
        right: -180px;
        top: -240px;

        @media (min-width: ${(p) => p.theme.bp.tablet}) {
          left: -100px;
          top: -580px;
          height: 1360px;
        }
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-height: 820px;
      display: grid;
      /* grid-template-columns: repeat(2, 1fr); */
      grid-template-columns: minmax(600px, 1fr) 1fr;
      grid-column-gap: 32px;
      align-items: center;

      h1 {
        font-size: 35px;
        margin-top: 64px;
      }
    }
  }
`;

export const VideoSection = styled.section`
  padding: 40px 0;

  h3 {
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
    margin-bottom: 24px;
  }

  p {
    font-weight: 350;
    font-size: 20px;
    line-height: 28px;
    color: ${(p) => p.theme.textLightGray};
    margin: 32px 0;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 96px 0;

    ${Container} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 32px;

      h3 {
        font-size: 35px;
        line-height: 42px;
      }

      .iframe-container {
        position: relative;
        width: calc(100% - 32px);
        border-radius: 0px 20px;
        height: 0;
        /* 16:9 */
        padding-top: 56.25%;
        /* 3:4 */
        /* padding-top: 75%; */
        align-self: center;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          padding: 0;
          box-sizing: border-box;
        }
      }

      .column-1 {
        grid-column: 1;
        grid-row: 1 / 4;
        background: blue;
      }

      .column-2 {
        grid-column: 2;
      }
    }
  }
`;

export const Missao = styled.section`
  padding: 100px 0;
  position: relative;
  overflow: hidden;

  p {
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: ${(p) => p.theme.branco};
    text-align: center;
    z-index: 1;
  }

  .bg-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 140px 0;

    p {
      max-width: 772px;
      font-size: 30px;
      line-height: 44px;
      margin: 0 auto;
    }
  }
`;

export const Sobre = styled.section`
  background: ${(p) => p.theme.branco};
  position: relative;

  .grid-1 {
    padding: 64px 0 32px;
  }

  h3 {
    font-weight: 600;
    font-size: 29px;
    line-height: 44px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
  }

  p {
    font-weight: 350;
    font-size: 15px;
    line-height: 25px;
    color: ${(p) => p.theme.textLightGray};
    margin-top: 32px;
  }

  .img-couple-wrapper {
    display: block;
    width: calc(100vw + 64px);
    margin-left: -32px;

    .img-couple {
      object-position: -50px 50%;
    }
  }

  .paralax2 {
    display: none;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    position: relative;

    h3 {
      font-size: 35px;
      line-height: 44px;
      max-width: 470px;
    }

    p {
      font-size: 17px;
      line-height: 28px;
    }

    & ${Container} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .grid-1 {
      padding: 64px 0;
      position: relative;

      .paralax2 {
        position: absolute;
        display: block;
        left: -258px;
        top: -80px;
      }
    }

    .img-couple-wrapper {
      display: block;
      position: absolute;
      height: 100%;
      width: 45%;
      right: 0;
      margin-left: 0;

      .img-couple {
        object-position: 50% 50%;
      }
    }
  }
`;

export const Depoimento = styled.section`
  padding: 64px 0;
  background: ${(p) => p.theme.degradeAzul};

  .card {
    background: ${(p) => p.theme.branco};
    position: relative;
    padding: 24px 20px 32px;
    text-align: center;
    box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.25);
    border-radius: 0px 20px;

    img.aspas {
      position: absolute;
      top: -16px;
      right: 24px;
    }

    .foto {
      border-radius: 120px;
      height: 120px;
      width: 120px;
      z-index: 11;
    }

    p.texto {
      margin-top: 32px;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: ${(p) => p.theme.textDarkerGray};
    }

    p.nome {
      color: ${(p) => p.theme.textDarkerGray};
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 28px;
      margin-top: 16px;
    }

    p.cargo {
      color: ${(p) => p.theme.textGray};
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 28px;
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-width: 720px;
      margin: 0 auto;
      padding: 24px 32px 32px;

      img.aspas {
        right: 84px;
        top: -24px;
      }
    }
  }
`;

export const Clientes = styled.section`
  padding: 32px 0;
  background: ${(p) => p.theme.branco};

  h4 {
    color: ${(p) => p.theme.primary};
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }

  p {
    color: ${(p) => p.theme.textGray};
    font-size: 17px;
    line-height: 28px;
    margin-top: 24px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 88px 0;

    ${Container} {
      max-width: 764px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      h4 {
        margin-left: auto;
        margin-right: auto;
        max-width: 640px;
        margin: 0 auto;
        font-size: 35px;
        line-height: 44px;
      }
    }
  }
`;

export const Produtos = styled.section`
  padding: 64px 0;
  background: ${(p) => p.theme.branco};

  ${Container} > h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.01em;
    background: ${(p) => p.theme.degradeMisto};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 40px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding-top: 84px;
    padding-bottom: 84px;

    ${Container} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 32px;
      padding-top: 124px;
      padding-bottom: 124px;

      .column-1 {
        grid-column: 1;
      }
      .row-1 {
        grid-row: 1;
      }
      .column-2 {
        grid-column: 2;
      }
      .row-2 {
        grid-row: 2;
      }
    }

    ${Container} > h3 {
      font-size: 35px;
    }
  }
`;

export const Final = styled.section`
  padding: 52px 0;
  background: ${(p) => p.theme.bgGray};
  text-align: center;

  h5 {
    font-weight: 600;
    font-size: 30px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
    margin-bottom: 44px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 84px 0;

    h5 {
      font-size: 36px;
      margin-bottom: 56px;
      max-width: 520px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
