import React, { useState } from "react";
import { Container } from "../../styles/UI";

import Layout from "../../components/Layout";
import ProductCard from "../../components/ProductCard";
import StyledLink from "../../DesignSystem/StyledLink";

import taxcelBgLogo from "../../images/home/logo-negative.svg";
import paralax2 from "../../images/paralax2.svg";
import aspas from "../../images/aspas2.svg";

import * as S from "./style";
import { StaticImage } from "gatsby-plugin-image";
import ApresentacaoHome from "../home/apresentacao";
import Input from "../../DesignSystem/Input";
import Button from "../../DesignSystem/Button";

import Clientes from "../../components/Clientes";
import { navigate } from "gatsby-link";
import Seo from "../../components/Seo";

export default function Sobre() {
  const [email, setEmail] = useState("");

  const handleClick = () => {
    // modalRef1.current.openModal();
    navigate("/baixar-taxsheets#formulario", { state: { email } });
  };

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setEmail(value);
  };
  return (
    <Layout>
      <Seo title="Sobre" />
      <S.Hero>
        <Container>
          <div className="cta">
            <h1>
              Simplificamos o dia a dia de quem trabalha com tributos no Brasil
            </h1>
            <p>
              O setor tributário está em plena transformação digital e a gente
              acredita que, através da tecnologia, podemos abrir novas
              possibilidades para o seu futuro, valorizando o melhor de cada
              profissional da área fiscal.
            </p>
          </div>
          <div className="illustration">
            <img src={taxcelBgLogo} alt="" className="bg-logo" />
          </div>
        </Container>
      </S.Hero>
      <S.VideoSection>
        <Container>
          <h3 className="column-2">
            Você sabe quanto tempo uma empresa gasta lidando com impostos no
            Brasil?
          </h3>
          <div className="iframe-container column-1">
            <iframe
              src="https://www.youtube.com/embed/vgxcIu0-aY8"
              title="video-taxcel"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p className="column-2">
            Anualmente, o Banco Mundial publica o relatório Doing Business, em
            que se mede o ambiente de negócios de 190 países. Dentre os
            critérios avaliados, está o tempo médio que uma empresa gasta por
            ano para apurar e pagar os seus impostos.
          </p>
          <p className="column-2">
            Com incríveis 1.501 horas, o Brasil amarga a última colocação no
            ranking nesse quesito. Para se ter uma ideia, a média da América
            Latina é de aproximadamente 317 horas enquanto a média dos países da
            OCDE é de 158 horas.
          </p>
        </Container>
      </S.VideoSection>
      <S.Missao>
        <Container>
          <StaticImage
            imgClassName="bg"
            className="bg-wrapper"
            src="../../images/bg-blue-image.jpg"
            alt=""
            quality={100}
            layout="fullWidth"
          />
          <p>
            A missão da Taxcel é simplificar a vida dos profissionais da área
            fiscal por meio da tecnologia, permitindo que cada um foque naquilo
            que realmente importa: seu potencial em analisar, planejar e inovar.
          </p>
        </Container>
      </S.Missao>
      <S.Sobre>
        <Container $clip>
          <div className="grid-1">
            <img src={paralax2} className="paralax2" alt="" />
            <h3>Somos uma empresa de tecnologia tributária</h3>
            <p>
              Somos uma empresa de tecnologia especializada em criar soluções
              para os profissionais da área tributária. Sempre movidos pelo
              pioneirismo em unir conhecimentos em tecnologia e tributos, temos
              como propósito simplificar a vida dos profissionais da área fiscal
              por meio de plataformas plug and play que agilizam a gestão da
              carga tributária e de compliance tributário nas empresas.
            </p>
            <p>
              Nossa missão é ser a empresa de tecnologia tributária mais
              inovadora do mercado, e buscamos sempre tornar mais fácil o
              trabalho daqueles que lidam com a apuração e gestão de tributos.
              Entendemos que a otimização da rotina fiscal é o melhor caminho
              para que as informações prestadas ao fisco tenham mais qualidade
              e, sobretudo, possam ser utilizadas como indicadores para a gestão
              das empresas, orientando decisões baseadas em dados.
            </p>
            <StyledLink mt={24} to="/produtos" arrow>
              Conheça nossos produtos
            </StyledLink>
          </div>
          <StaticImage
            src="../../images/notebook.jpg"
            alt="foto de um notebook com gráficos na tela"
            quality={100}
            className="img-couple-wrapper"
            imgClassName="img-couple"
          />
        </Container>
      </S.Sobre>
      <S.Depoimento>
        <Container>
          <div className="card">
            <img src={aspas} className="aspas" alt="" />
            <StaticImage
              src="../../images/testimonial/fotos/PedroLima.jpg"
              className="foto"
            />
            <p className="texto">
              "Após mais de 15 anos de experiência na área fiscal, passando
              por empresas BigFour e consultorias tributárias renomadas no
              mercado, fundei a Taxcel com o objetivo de facilitar e simplificar
              a rotina dos profissionais da área, ajudando-os em sua jornada de
              transformação digital."
            </p>
            <p className="nome">Pedro Lima</p>
            <p className="cargo">CEO e fundador da Taxcel</p>
          </div>
        </Container>
      </S.Depoimento>
      <S.Clientes>
        <Container>
          <h4>
            Desde 2014, simplificamos o dia a dia de quem trabalha com tributos
            no Brasil
          </h4>
          <p>
            Ao longo de nossa trajetória, já atendemos mais de 730 clientes de
            diferentes categorias de mercado, sendo mais de 300 grandes
            corporações. Além disso, fomos selecionados para o programa
            Microsoft for Startups, participamos do hub de inovação Habitat do
            InovaBra e somos premiadas no Ranking 100 Open Startups com TOP 8
            Open Scaleups.
          </p>
          <Clientes twoRows />
          <Input
            value={email}
            onChange={handleChange}
            placeholder="Seu email"
            center
          >
            <Button onClick={handleClick}>Teste já</Button>
          </Input>
          <StyledLink to="/planos" mt={20} arrow>
            Conheça nossos planos
          </StyledLink>
        </Container>
      </S.Clientes>
      <ApresentacaoHome />
      <S.Produtos>
        <Container $clip>
          <h3 className="column-1 row-1">
            Conheça nossa solução para Compliance
          </h3>
          <ProductCard className="column-1 row-2" product="taxsheets" />
          <h3 className="column-2 row-1">
            Conheça nossa solução para Analytics e BI
          </h3>
          <ProductCard className="column-2 row-2" product="taxdashs" />
        </Container>
      </S.Produtos>
      <S.Final>
        <Container>
          <h5>Inicie hoje sua jornada em direção ao Tax Transformation</h5>
          <Input
            value={email}
            onChange={handleChange}
            placeholder="Seu email"
            center
          >
            <Button onClick={handleClick}>Teste já</Button>
          </Input>
          <StyledLink to="/planos" mt={16} arrow>
            Conheça nossos planos
          </StyledLink>
        </Container>
      </S.Final>
    </Layout>
  );
}
